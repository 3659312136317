import { useDispatch, useSelector } from 'react-redux';
import Message from './Message';
import './messageContainer.css';
import { deleteMessage, initMessage, resetMessageLang, selectMessages } from '../../slice/MessageSlice';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { selectStreaming, setStreaming, selectFirstRender, selectClickTranslate, setClickTranslate, selectShowLoader, selectTranslating, setTranslating } from '../../slice/loaderSlice';
import LoadingMessage from './LoadingMessage';
import { useState } from 'react';
import { selectDevice } from '../../slice/deviceSlice';
import { setInputMessage, setSend } from "../../slice/InputMessageSlice";
import { selectBearer } from '../../slice/bearerSlice';
import Loader from './Loader/Loader';
import SendArrow from '../../Images/SendArrow';
import { setRequest } from '../../slice/userActionsSlice';
import MessageTopic from './MessageTopic';
import FakeMessageTopic from './FakeMessageTopic';
import MaximumQueryWarningModal from '../SendMessageContainer/MaximumQueryWarningModal';
import ExpiredQuestionModal from './ExpiredQuestionModal';
import { selectIsTopicVisible } from "../../slice/ConversationSlice";



function MessageContainer() {
//console.log('\n---------MessageContainer--------')
    let conversation = [];
    const messages = useSelector(selectMessages);
    const messageRef = useRef([]);
    const dispatch = useDispatch();
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const wait = (streaming || translating ? true : false);
    const [disable, setDisable] = useState(false);
    const device = useSelector(selectDevice);
    const clearRef = useRef(null);
    const containerRef = useRef(null);
    const isClearVisible = useIsVisible(clearRef);
    const [clickClear, setClickClear] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [reload, setReload] = useState(true);
    const firstRender = useSelector(selectFirstRender);
    const clickTranslate = useSelector(selectClickTranslate);
    const logToken = useSelector(selectBearer);
    const showLoader = useSelector(selectShowLoader);
    const scrollLimit = (device === 'pc' ? 200 : 100);
    const isActiveTopicVisible = useSelector(selectIsTopicVisible);


    //reload page during streaming
    if (reload && streaming && !firstRender) {
        dispatch(setStreaming(false));
    //    dispatch(setInputMessage((messages[messages.length - 2]).text)); pre conversation
    //    dispatch(deleteMessage()); // pre conversation
    //    dispatch(setSend("send")); /* to trigger the "send" in useffect in messagebutton*/
    }

    //reload page during translation
    if (reload && translating && !firstRender) {
        dispatch(setTranslating(false));
        dispatch(resetMessageLang());
    }

    useLayoutEffect(() => {
        setReload(false);
    }, []);

    useEffect(() => {
        clearRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
    }, [scrollDown])

    useEffect(() => { //to manage page scroll
        if (messages.length === 1) {
            containerRef.current.scroll(0, 0);
        } else if (clickTranslate !== undefined && clickTranslate.show && messageRef.current.length > 1) {
            //il clickTranslate.show non dovrebbe mai essere undefined, ma ha dato dei problemi, metto controllo di sicurezza, da controllare le stampe
            //anche il controllo sulle ref non dovrebbe servire, da controllare se aggiorna correttamente il clickTranslate.show ha sempre il valore corretto
            try {
                messageRef.current[clickTranslate.i].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            } catch(e) {
                console.log('scrollError0', e)
            }
            dispatch(setClickTranslate({ show: false }));
        } else if (streaming) {
            if (showLoader || (containerRef.current.scrollHeight - containerRef.current.scrollTop - containerRef.current.clientHeight) <= scrollLimit) {
                try {
                    scrollToCorrectMessage();
                } catch(e) {
                    console.log('scrollError1', e)
                }
            }
            if(messages[messages.length - 1].type !== 'answer') {
                if (messages[messages.length - 1].rcvCits !== undefined) { //questo if serve a settare streaming false quando il messaggio corrente ha ricevuto l'ultimo aggiornamento finale relativo alle cits
                    dispatch(setStreaming(false));
                }
            }
        } else if (!streaming && /^\-1(\.\d)?$/.test(messages[messages.length - 1].statusCode)) {
            messageRef.current[messageRef.current.length - 2].scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
        } else {
            if(reload) {
                try {
                    scrollToCorrectMessage();
                } catch(e) {
                    console.log('scrollError2', e)
                    try {
                        messageRef.current[messageRef.current.length - 1].scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
                    } catch(e) {
                        console.log('scrollError3', e)
                    }
                }
            }
        }
    }, [messages, isActiveTopicVisible])

    useEffect(() => {
        if (wait) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [wait])

    useEffect(() => {
        if (messageRef.current.length > 1) {
            messageRef.current = messageRef.current.filter((ref) => { return ref !== null });
        }
    }, [logToken, clickClear])

    function scrollToCorrectMessage() {
        if(messages[messages.length - 1].type === 'question') {
            messageRef.current[messageRef.current.length - 1].scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
        } else {
            if(messages[messages.length - 1].type === 'answer') {
                messageRef.current[messageRef.current.length - 2].scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
            } else {
                if(messages[messages.length - 1].type === 'topic' && messages[messages.length - 2].type === 'answer') { // Q dopo inizio generazione A
                    messageRef.current[messageRef.current.length - 3].scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
                } else {
                    // ricerca normale
                    messageRef.current[messageRef.current.length - 2].scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
                }
            }
        }
    }

    function useIsVisible(ref) {
        const [isIntersecting, setIntersecting] = useState(false);
        useEffect(() => {
            const observer = new IntersectionObserver(([entry]) =>
                setIntersecting(entry.isIntersecting)
            );
            observer.observe(ref.current);
            return () => {
                observer.disconnect();
            };
        }, [ref]);
        return isIntersecting;
    }

    function scrollClick() {
        setScrollDown(!scrollDown);
    }

    if (messages.length !== 0) {
        messages.map((mex, i) => {
     //       console.log('mex', mex, messages.length - 1, i)
            if (streaming && messages.length - 1 === i && showLoader) {
                if (!reload || firstRender) {
                    if(mex.type !== 'question') {
                        conversation.push(<LoadingMessage key={i} ind={i} ref={el => messageRef.current[i] = el} />);
                    } else {
                        conversation.push(<Message key={i} ind={i} ref={el => messageRef.current[i] = el} />);
                    }
                }
            } else {
                if(mex.type === 'topic') {
                    if(mex.isExampleTopic === true) {
                        conversation.push(<FakeMessageTopic key={i} ind={i} mex={mex} ref={el => messageRef.current[i] = el} />);
                    } else {
                        conversation.push(<MessageTopic key={i} ind={i} mex={mex} ref={el => messageRef.current[i] = el} />);
                    }
                } else {
                    conversation.push(<Message key={i} ind={i} ref={el => messageRef.current[i] = el} />);
                }
            }
            return null;
        });
    }

    function clearMessage() {
        dispatch(initMessage());
        dispatch(setRequest(0));
        setClickClear(!clickClear); //to trigger the update of the references
    }

    return (
        <div name="messageContainer" className={device} ref={containerRef}>
            {isClearVisible || (streaming && !showLoader) ? null : <button name='button-scrolldown' className='secondary-button small display-align-center' onClick={scrollClick}><span style={{ marginRight: '4px' }}>SCROLL DOWN</span> <SendArrow /></button>}
            {conversation}
            {showLoader ? <Loader /> : null}
            <span name='clear-span' className='span-align-right'>
                <button ref={clearRef} name='clear' className='secondary-button small' onClick={() => { clearMessage() }} disabled={disable}>CLEAR</button>
            </span>
            <MaximumQueryWarningModal />
            <ExpiredQuestionModal />
            <div style={{height: '70vh'}}></div>
        </div>
    );
}

export default MessageContainer;