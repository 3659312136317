import React from 'react';
import robot from '../../Images/varchat-icon-robot-2.svg';
import robotDark from '../../Images/varchat-icon-robot-dark.svg';
import user from '../../Images/varchat-icon-user-2.svg';
import userDark from '../../Images/varchat-icon-user-dark.svg';
import { forwardRef, useState, useEffect } from 'react';
import Rating from './Rating/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessages, updateMessage } from '../../slice/MessageSlice';
import examples from '../../constants/example';
import { isMobile } from 'react-device-detect';
import { selectLanguage, selectTheme } from '../../slice/SettingSlice';
import MessageFlagIcons from './MessageFlagIcons';
import { setShowHelpCenter, setShowLogin, setShowProfile } from '../../slice/ModalVisibilitySlice';
import Loader from './Loader/Loader';
import { selectBearer, setExpired } from '../../slice/bearerSlice';
import { selectStreaming, selectTranslating, selectWaitingForMaxQueryResponse } from '../../slice/loaderSlice';
import WarningIcon from '../../Images/WarningIcon';
import ErrorIcon from '../../Images/ErrorIcon';
import ExampleList from './ExampleList';
import TopicContainer from './TopicContainer';
import { Url } from '../../constants/global.js';
import { selectActiveConversationUuid, selectIsTopicVisible, selectIsTopicClickable } from "../../slice/ConversationSlice";


const MessageTopic = forwardRef(function Message(props, ref) {
    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);
    let message = messages[props.ind];
    message.mex ? message = message.mex : message = message;
    const theme = useSelector(selectTheme);
    const robotIcon = theme === 'light' ? robot : robotDark;
    const userIcon = theme === 'light' ? user : userDark;
    const language = useSelector(selectLanguage);
    const logToken = useSelector(selectBearer);
    const translating = useSelector(selectTranslating);
    const streaming = useSelector(selectStreaming);
    const [isFirstRender, setIsFirstRender] = useState(true); // al primo rendere non deve essere mostrato finchè non termina la ricerca
    const waitingForMaxQueryResponse = useSelector(selectWaitingForMaxQueryResponse);
    const [triggerAfterUpdatingBearer, setTriggerAfterUpdatingBearer] = useState(false);

    const activeConversationUuid = useSelector(selectActiveConversationUuid);
    const isActiveTopicClickable = useSelector(selectIsTopicClickable);
    const isActiveTopicVisible = useSelector(selectIsTopicVisible);

    let messageBody = '';
    let messageToCopy = '';
    const statusInfoMessage = 'We cannot retrieve all the information at this moment, so the answer may be partial or incomplete. If you\'re not satisfied with this summary, please try again later.';
    const errMessage = <span>VarChat is unable to complete this request right now because of an internal error. Please, try again.<br />If the problem persists, please contact <a className='link-error' href='mailto:varchat@engenome.com'>varchat@engenome.com</a></span>;
    const helpCenterLink = <div className='mt-2'>For more details on the supported input formats, please visit our <span className='message-link' onClick={() => { dispatch(setShowHelpCenter(true)) }}>Help Center</span></div>
    const statusCodeLegend = [
        { statusCode: -1, type: 'errorValidation', text: '' }, //-1 case fe validation fail, i messaggi sono gestiti in SendMessageButton in base all'errore di validazione
        { statusCode: 0, type: (message.statusInfoCode ? 'warning' : 'ok'), text: '' }, //type warning serve per inserire l'icona gialla quando è 0 ma Status_info_code è true
        { statusCode: 1, type: 'warning', text: 'VarChat identified supporting literature, but it was not used to generate the summary.' },
        { statusCode: 2, type: 'warning', text: 'VarChat did not find any supporting literature.' },
        { statusCode: 3, type: 'error', text: 'We\'re currently encountering issues and can\'t provide a summary right now. Please try again later.' },
        { statusCode: 4, type: 'error', text: (message.statusInfoCode ? errMessage : 'VarChat is unable to retrieve information on this variant. Please check the information you provided, or try another VarChat-supported format (HGVS or genomic coordinates notation).') },
        { statusCode: 5, type: 'error', text: errMessage },
        { statusCode: 6, type: 'error', text: (message.statusInfoCode ? errMessage : 'These genomic coordinates seem to be incorrect. Please check the information you provided and verify the genomic assembly. Alternatively, try another VarChat-supported format (HGVS or rs notation).') },
        { statusCode: 7, type: 'error', text: (message.statusInfoCode ? errMessage : 'Please check the gene symbol since it’s not valid.') },
        { statusCode: 8, type: 'error', text: (message.statusInfoCode ? errMessage : 'Please check the transcript ID you provided or try to query by gene.') },
        { statusCode: 9, type: 'error', text: 'VarChat is unable to suggest further questions. If the problem persists, please contact varchat@engenome.com' },
        { statusCode: 10, type: 'warning', text: 'VarChat is unable to find the answer to this question in the identified literature.' },
        { statusCode: 11, type: 'warning', text: 'The queried dbSNP id is only mentioned in Supplementary Information, therefore we cannot provide a summary.' }
    ];

    //metto il messaggio dell'errore di traduzione perché non si può salvare in uno stato un html
    const translationError = <span className='error'><ErrorIcon /><span>I was unable to complete the translation right now. Please try again later. <br />If the problem persists, please contact <a className='link-error' href='mailto:varchat@engenome.com'>varchat@engenome.com</a></span></span>;
    const translation = (message.translation && message.translation[message.language] !== undefined ? (message.translation[message.language] === 'error' ? (translating ? <Loader loading_message={'TRANSLATING...'} /> : translationError) : message.translation[message.language]) : <Loader loading_message={'TRANSLATING...'} />); //in message.translation mi salvo le n traduzioni nelle varie lingue

    let messageText = "";//(message.language && message.language !== 'EN' ? translation : message.text);
    const [responseJSON, setResponseJSON] = useState(null);
       
    useEffect(() => { //to set time only once
        if (!message.time) {
            console.log('TOPIC dispatch update message from useEffect')
            dispatch(updateMessage({ i: props.ind, obj: { time: getFormattedTime() } }))
        }
    }, []);

    useEffect(()=>{
        if(triggerAfterUpdatingBearer) {
            setTriggerAfterUpdatingBearer(false);
            if(logToken !== '') {
                if(!message.duplicatedMessage) fetchQuestions();
            }
        }
    }, [logToken]);

    useEffect(() => {
        if(!message.duplicatedMessage) fetchQuestions();
    }, []);

    function getFormattedTime() {
        const date = new Date();
        const timeComponents = [date.getHours(), date.getMinutes()];
        return timeComponents
            .map(component => {
                const pad = (component < 10) ? '0' : '';
                return pad + component;
            })
            .join(':');
    }

    function getStars(count) {
        let stars = [];
        for (let i = 0; i < count; i++) {
            stars.push(<span key={'star ' + i} className='rating-icon full clinvar-stars-check' />)
        }
        return stars;
    }

    //function to extract the text of an html react element (if the passed object is already a string returns it)
    function textContent(elem) {
        if (!elem) {
            return '';
        }
        if (typeof elem === 'string') {
            return elem;
        }
        const children = elem.props && elem.props.children;
        if (children instanceof Array) {
            return children.map(textContent).join('');
        }
        return textContent(children);
    }

    if (message.statusCode !== undefined) {
        let messageStatus = message.statusCode;
        if(/^\-1(\.\d)?$/.test(message.statusCode)){
            messageStatus = -1;
        }
        let statusCodeText = statusCodeLegend[messageStatus + 1].text;
        if(message.statusInfoCode && statusCodeLegend[messageStatus + 1].type !== 'error'){
            statusCodeText = statusInfoMessage;
        }

        messageBody = <><p className={statusCodeLegend[messageStatus + 1].type}>
            {statusCodeLegend[messageStatus + 1].type === 'warning' ? <WarningIcon /> : statusCodeLegend[messageStatus + 1].type === 'error' ? <ErrorIcon /> : null}
            {statusCodeText}
        </p>
            {!message.statusInfoCode && examples['status_' + messageStatus] !== undefined ? <><ExampleList type={'status_' + messageStatus} />{helpCenterLink}</> : null}
            {messageText}
            {messageStatus === -1 ? <>{message.statusCode !== -1 ? <div className='mt-2'><ExampleList type={'status_' + message.statusCode.toString().split('.')[1]} /></div> : null}{helpCenterLink}</> : null}
            {message.stopText !== undefined ? <div style={{ color: 'var(--soft-red)' }}>{message.stopText}</div> : ''}
        </>
        let title = ''
        try{
            title = '\n' + examples['status_' + messageStatus].title;
        } catch{
        }
        messageToCopy = textContent(statusCodeText) + (!message.statusInfoCode && examples['status_' + messageStatus] !== undefined ? '\n' + examples['status_' + messageStatus].text + title + '\n' + examples['status_' + messageStatus].exArray.map((str) => { return str.ex }).join('\n') + '\nNote: ' + examples['status_' + messageStatus].note + '\n' + textContent(helpCenterLink) + '\n' : '') + (messageText !== undefined ? messageText : '');
        if(messageStatus === -1 && message.statusCode !== -1){
            try{
                title = '\n' + examples['status_' +message.statusCode.toString().split('.')[1]].title;
            }catch{}
            messageToCopy = messageToCopy.concat('\n'  + examples['status_' + message.statusCode.toString().split('.')[1]].text + title + '\n' + examples['status_' + message.statusCode.toString().split('.')[1]].exArray.map((str) => { return str.ex }).join('\n') + '\nNote: ' + examples['status_' + message.statusCode.toString().split('.')[1]].note + '\n' + textContent(helpCenterLink));
        }
    } else {
        messageBody = <>{messageText}{message.stopText !== undefined ? <div style={{ color: 'var(--soft-red)' }}>{message.stopText}</div> : ''}</>;
        messageToCopy = textContent(messageText);
        if (props.ind === 0) {
            messageToCopy = messageToCopy.concat('\n' + examples['welcome'].exArray.map((str) => { return str.ex }).join('\n'));
        }
    }


    async function fetchQuestions() {
        const fetchPromise = fetch(Url + '/varchat/api/request/questions?' + new URLSearchParams({ conversation_uuid: props.mex.conversationUuid }), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerAfterUpdatingBearer(true);
                    }
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return response.json();
                }
            }).then((data) => {
                setResponseJSON(JSON.stringify(data));
                let isObsolete;
                try {
                    data.chat.length === 0 && data.status_answer_code === 0 ? isObsolete = true : isObsolete = false;
                } catch(e) {}
                dispatch(updateMessage({ i: props.ind, obj: Object.assign({}, message, {text: JSON.stringify(data), evaluate: true}, {statusCode: data.status_answer_code, isObsolete: isObsolete, duplicatedMessage: true } ) }));
            }).catch((error) => {
                console.log(error)
            });
    }

    const isEmpty = () => {
        try {
            return JSON.parse(message.text).chat.length === 0;
        } catch(e) {
            return true;
        }
    }

    const hasStatusWarningCodeZero = () => {
        try {
            return JSON.parse(message.text).status_answer_code === 0;
        } catch(e) {
            return false;
        }
    }

    const isMessageVisible = () => {
        try {
            return message.isObsolete !== true;
        } catch(e) {
            return true;
        }
    }

    return (
        <>
            { (isEmpty() && hasStatusWarningCodeZero()) || !isMessageVisible()
                ? ""
                : 
                    <>  
                    {!activeConversationUuid || activeConversationUuid !== props.mex.conversationUuid
                        ? ""
                        : activeConversationUuid === props.mex.conversationUuid //&& isActiveTopicVisible 
                            ?
                            <span className={isFirstRender ? (streaming ? 'message hidden' : 'message') : (waitingForMaxQueryResponse || streaming ? 'message disabled no-pointer-events' : 'message')} ref={ref} key={'message ' + props.ind}>
                                {isFirstRender ? (!waitingForMaxQueryResponse && !streaming) ? setIsFirstRender(false) : null : null}
                                {isMobile
                                    ? null
                                    : message.user === 'VarChat'
                                        ?   <img src={robotIcon} alt='varChat' className='icon'></img>
                                        :   <img src={userIcon} alt='user' className='icon'></img>}

                                <span name='message-text' className={message.user.toLowerCase()}>
                                    <span name='message-header'>
                                        <span>
                                            {!isMobile
                                                ? null
                                                : message.user === 'VarChat'
                                                    ?   <img src={robotIcon} alt='varChat' className='icon' style={{ marginRight: '8px' }}></img>
                                                    :   <img src={userIcon} alt='user' className='icon' style={{ marginRight: '8px' }}></img>}
                                            <span style={{ display: isMobile ? 'block' : 'flex' }}>
                                                <p name='name'>{message.user}</p>
                                                <p name='time'>{message.time}</p>
                                            </span>
                                        </span>

                                    </span>
                                    <div style={{ whiteSpace: 'pre-line', lineBreak: (message.linebreak !== undefined && message.linebreak) ? 'anywhere' : 'auto' }}>
                                        {/* message body cambia in base alla lingua */}
                                        <div style={{ direction: message.language === 'AR' ? 'rtl' : '' }}>
                                            {messageBody}
                                        </div>
                                    </div>
                                    { JSON.parse(message.text).chat.map((topic, j) => {
                                        return <TopicContainer key={j} topic={topic} ind={props.ind} conversationUuid={props.mex.conversationUuid} cits={props.mex.cits} citCount={props.mex.citCount} dateTime={JSON.parse(message.text).date_time}/>
                                    }) }
                                    
                                    {isMessageVisible() && message.evaluate && statusCodeLegend[message.statusCode + 1].type !== 'error' 
                                        ? <><br></br><Rating ind={props.ind} scope={'topic'} /></> 
                                        : null
                                    }
                                
                                </span>
                                
                            </span>
                            : ""}
                    </>
            }
        </>
        
    );
})

export default MessageTopic;